import React, { useEffect } from 'react'

const UTMCookieSetter = () => {
    useEffect(() => {
        // Функция для получения параметров из URL
        function getUTMParams() {
            const urlParams = new URLSearchParams(window.location.search)
            const utmSource = urlParams.get('utm_source')
            const utmMedium = urlParams.get('utm_medium')
            const utmCampaign = urlParams.get('utm_campaign')
            const utmTerm = urlParams.get('utm_term')
            const utmContent = urlParams.get('utm_content')

            return { utmSource, utmMedium, utmCampaign, utmTerm, utmContent }
        }

        // Функция для записи параметров в cookies
        function setUTMCookies() {
            const utmParams = getUTMParams()

            if (
                utmParams.utmSource ||
                utmParams.utmMedium ||
                utmParams.utmCampaign ||
                utmParams.utmTerm ||
                utmParams.utmContent
            ) {
                const cookieData = JSON.stringify(utmParams)
                const expirationDate = new Date()
                expirationDate.setTime(
                    expirationDate.getTime() + 7 * 24 * 60 * 60 * 1000,
                ) // Срок хранения - 7 дней

                document.cookie = `utm=${cookieData}; expires=${expirationDate.toUTCString()}; path=/;`
                console.log('UTM cookies set:', cookieData)
            }
        }

        // Вызов функции при монтировании компонента
        setUTMCookies()
    }, []) // Пустой массив зависимостей, чтобы выполнить только один раз при монтировании

    return null // Этот компонент ничего не отображает
}

export default UTMCookieSetter
