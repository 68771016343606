import React, { useEffect, useState } from 'react'
import classes from './record.module.css'
import OrangeHeader from '../Header/OrangeHeader'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar'
import BrandBtn from '../UI/Buttons/BrandBtn'
import axios from 'axios'
import { apiUrl } from '../../../utils/constants'

const Timing = (props) => {
    const params = useParams()
    const location = useLocation()
    const recordData = useLocation()
    recordData.service = Number(params.id)
    const navigate = useNavigate()
    let maxDate = new Date()
    maxDate.setMonth(maxDate.getMonth() + 1)

    const [value, onChange] = useState(new Date())
    const [phone, setPhone] = useState()
    const [name, setName] = useState('')
    const [openTime, setOpenTime] = useState([])

    const MyCalendar = () => {
        return (
            <div className={classes.calendar}>
                <Calendar
                    onChange={onChange}
                    value={value}
                    minDate={new Date()}
                    maxDate={maxDate}
                    prev2Label={null}
                    next2Label={null}
                    className={classes.cal}
                />
            </div>
        )
    }

    const [time, setTime] = useState(0)
    const [timeOrder, setTimeOrder] = useState(0)

    const TimeWindow = (props) => {
        return (
            <div className={classes.time_window}>
                <div className={classes.title}>Выберите время</div>
                <div className={classes.times_bl}>
                    {openTime.map((item, index) => {
                        return (
                            <button
                                className={`${classes.time} ${time === item ? classes.time_active : ''}`}
                                value={item}
                                key={index}
                                onClick={(e) => {
                                    const queryTime = e.target.value.split(':')
                                    console.log(queryTime)
                                    const newTime = new Date(value)
                                    newTime.setHours(Number(queryTime[0]))
                                    newTime.setMinutes(Number(queryTime[1]), 0)
                                    console.log(newTime)
                                    setTimeOrder(newTime)
                                    setTime(e.target.value)
                                    location.state.recordData.orderTime =
                                        newTime
                                }}
                            >
                                {item}
                            </button>
                        )
                    })}
                </div>
            </div>
        )
    }

    //Форматирование времени
    const dateString =
        value.getFullYear() +
        '/' +
        value.getMonth() +
        '/' +
        value.getDate() +
        ' ' +
        time

    const [allert, setAllert] = useState('')
    const record = () => {
        if (time !== undefined && time !== 0) {
            setAllert('')
            console.log(timeOrder)

            //window.location.replace(location.pathname + '/success');
        } else {
            setAllert('Выберите время!')
        }
    }

    try {
    } catch (error) {}

    //Получить массив свободных окон для записи
    useEffect(() => {
        const getTimingForOrders = async () => {
            try {
                const dateOnly = new Date(
                    value.getFullYear(),
                    value.getMonth(),
                    value.getDate(),
                )
                location.state.recordData.dateGetTime = dateOnly
                if (location?.state?.recordData?.service_id) {
                    location.state.recordData.service_id = Number(params.id)
                }
                const response = await axios.post(
                    `${apiUrl}/api/orders/getFreeTime`,
                    location.state.recordData,
                )
                if (response.data) {
                    setOpenTime(response.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        getTimingForOrders()
    }, [value])

    //Добавить визит и редирект на страницу success
    const handleConfirmation = async () => {
        try {
            const response = await axios.post(
                `${apiUrl}/api/orders`,
                location.state.recordData,
            )
            const successUrl = `${location.pathname}/success?orderId=${response.data.id}`
            navigate('/order/' + response.data.id)
        } catch (error) {
            console.log(error)
        }
    }

    const checkCustomersForUser = async (phone) => {
        try {
            const params = {
                user: location.state.recordData.user_id,
                phone: phone,
            }
            const response = await axios.post(
                `${apiUrl}/api/customers/find`,
                params,
            )
            if (response) {
                showNameImput(response.data)
            }
        } catch (error) {}
    }

    const showNameImput = (props) => {
        if (props) {
            setName(props.name)
        }
    }

    return (
        <div className="client_container">
            <OrangeHeader title="Выберите время" />
            <MyCalendar />
            <TimeWindow />
            <div className={classes.title}>Контактная информация</div>
            <div className={classes.phone_bl}>
                <input
                    type="phone"
                    value={phone}
                    className={classes.input_phone}
                    placeholder="Телефон"
                    onChange={(e) => {
                        const phone = e.target.value
                        const sanitizedValue = phone.replace(/\D/g, '')
                        if (phone.length >= 11) {
                            checkCustomersForUser(phone)
                        }
                        setPhone(sanitizedValue)
                        location.state.recordData.phone = phone
                    }}
                />
            </div>
            <div className={classes.phone_bl}>
                <input
                    type="text"
                    value={name}
                    className={classes.input_phone}
                    placeholder="Имя"
                    onChange={(e) => {
                        const nameInput = e.target.value
                        setName(nameInput)
                        location.state.recordData.name = nameInput
                    }}
                />
            </div>
            <Link to="#" onClick={handleConfirmation}>
                {value &&
                time &&
                phone &&
                phone.length >= 11 &&
                name &&
                name.length >= 2 ? (
                    <BrandBtn body="Подтвердить" />
                ) : (
                    <BrandBtn body="Подтвердить" grey={true} disabled />
                )}
            </Link>
            <div>{allert}</div>
        </div>
    )
}

export default Timing
