import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    project: null, // Только один проект хранится в state
}

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProject: (state, action) => {
            const project = action.payload
            state.project = project
            console.log('Текущий проект:', project)

            // Сохранение в localStorage (перезаписываем прошлый проект)
            localStorage.setItem('current_project', JSON.stringify(project))
        },
        loadProjectFromStorage: (state) => {
            const savedProject = localStorage.getItem('current_project')
            if (savedProject) {
                state.project = JSON.parse(savedProject)
            }
        },
    },
})

export const { setProject, loadProjectFromStorage } = projectSlice.actions
export default projectSlice.reducer
