import Cookies from 'js-cookie' // Установите библиотеку js-cookie: npm install js-cookie

const JWTManager = () => {
    let refreshEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refresh`

    const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint)
    const getRefreshTokenEndpoint = () => refreshEndpoint

    // Получение токена из куки
    const getToken = () => Cookies.get('x-auth-cookie')
    const getRefreshToken = () => Cookies.get('x-auth-cookie-refresh')

    // Установка токена в куки
    const setToken = (token) => {
        Cookies.set('x-auth-cookie', token, {
            expires: 30, // Срок действия: 1 день
            secure: false, // Только для HTTPS
            sameSite: 'strict', // Защита от CSRF
        })
    }

    const setRefreshToken = (refreshToken) => {
        Cookies.set('x-auth-cookie-refresh', refreshToken, {
            expires: 30, // Срок действия: 7 дней
            secure: false,
            sameSite: 'strict',
        })
    }

    // Получение ролей из куки
    const getRoles = () => Cookies.get('userRoles')
    const setRoles = (roles) => {
        Cookies.set('userRoles', roles, {
            expires: 30, // Срок действия: 1 день
            secure: false,
            sameSite: 'strict',
        })
    }

    // Удаление токенов и ролей из куки
    const eraseToken = () => {
        Cookies.remove('x-auth-cookie')
        Cookies.remove('x-auth-cookie-refresh')
        Cookies.remove('userRoles')
        return true
    }

    const removeProjectId = () => {
        localStorage.removeItem('projectId')
    }

    return {
        eraseToken,
        getToken,
        getRefreshToken,
        setRefreshTokenEndpoint,
        getRefreshTokenEndpoint,
        setToken,
        setRefreshToken,
        getRoles,
        setRoles,
        removeProjectId,
    }
}

export default JWTManager()

// const JWTManager = () => {
//     let refreshEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refresh`;

//     const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);
//     const getRefreshTokenEndpoint = () => refreshEndpoint;

//     const getToken = () => localStorage.getItem('accessToken');
//     const getRefreshToken = () => localStorage.getItem('refreshToken');

//     const setToken = (token) => localStorage.setItem('accessToken', token);
//     const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

//     const getRoles = () => localStorage.getItem('userRoles');
//     const setRoles = (roles) => localStorage.setItem('userRoles', roles);

//     const eraseToken = () => {
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         localStorage.removeItem('userRoles');
//         return true;
//     };

//     return {
//         eraseToken,
//         getToken,
//         getRefreshToken,
//         setRefreshTokenEndpoint,
//         getRefreshTokenEndpoint,
//         setToken,
//         setRefreshToken,
//         getRoles,
//         setRoles
//     };
// };

// export default JWTManager();
