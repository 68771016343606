import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import OrangeHeader from '../../components/ClientPages/Header/OrangeHeader'
import ServiceItem from '../../components/ClientPages/Services/ServiceItem'
import ServicesList from '../../components/ClientPages/Services/ServiceItem'
import { apiUrl } from '../../utils/constants'

const ServicesPage = (props) => {
    const params = useParams()
    const location = useLocation()

    const [services, setServices] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        axios
            .get(
                `${apiUrl}/api/services/front/${params.id}/${location.state.recordData.user_id}`,
            )
            .then((response) => {
                console.log(response)
                if (response.data) {
                    setServices(response.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setServices(false)
                }
            })
    }, [])

    const recordData = location.state.recordData
    if (loading) {
        return <div>Loading...</div>
    } else {
        return (
            <div className="client_container">
                <OrangeHeader title="Выберите услугу" />
                <ServicesList services={services} recordData={recordData} />
            </div>
        )
    }
}

export default ServicesPage
