import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import './ModalAuth.css'
import authProvider from '../../../../../authProvider'
import CitySearchComponent from '../../search-cities/CitySearchComponent'

const ModalAuth = ({ isOpen, onClose, type = 'create' }) => {
    const apiUrl = process.env.REACT_APP_API_URL
    const [modalType, setType] = useState(type)
    const [formData, setFormData] = useState({
        login: '',
        password: '',
        passwordRepeat: '',
        name: '',
        city: '',
    })
    const [message, setMessage] = useState('')
    const buttonAuth = useRef(null)
    const buttonCreate = useRef(null)

    useEffect(() => {
        const allFieldsFilled = Object.values(formData).every(
            (field) => field.trim() !== '',
        )

        if (modalType === 'auth' && buttonAuth.current) {
            const validAuth = formData.login && formData.password
            buttonAuth.current.classList.toggle('btnCreate', validAuth)
            buttonAuth.current.classList.toggle('btnGrey', !validAuth)
            buttonAuth.current.disabled = !validAuth
        }

        if (modalType === 'create' && buttonCreate.current) {
            const validCreate =
                formData.name &&
                formData.login &&
                formData.password &&
                formData.passwordRepeat &&
                formData.password === formData.passwordRepeat &&
                formData.city.length >= 2

            buttonCreate.current.classList.toggle('btnCreate', validCreate)
            buttonCreate.current.classList.toggle('btnGrey', !validCreate)
            buttonCreate.current.disabled = !validCreate
        }

        let timeoutId
        const handleClickOutside = (event) => {
            if (!event.target.closest('.modalBl')) {
                onClose()
            }
        }

        if (isOpen) {
            timeoutId = setTimeout(() => {
                document.addEventListener('click', handleClickOutside)
            }, 100)
        }

        return () => {
            clearTimeout(timeoutId)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isOpen, onClose, formData, modalType])

    if (!isOpen) return null

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => {
            const newState = { ...prevState, [name]: value }

            if (
                newState.passwordRepeat &&
                newState.password !== newState.passwordRepeat
            ) {
                setMessage('Пароли не совпадают')
            } else {
                setMessage('')
            }
            return newState
        })
    }

    const handleCitySelect = (city) => {
        setFormData((prevState) => ({ ...prevState, city }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (modalType === 'auth') {
            const params = {
                username: formData.login,
                password: formData.password,
            }
            try {
                const response = await authProvider.login(params)
                if (response.status === 201) {
                    window.location.href =
                        process.env.REACT_APP_PUBLIC_URL + '/admin'
                } else {
                    setMessage('Логин или пароль введены не верно!')
                }
            } catch (error) {
                setMessage('Ошибка авторизации!')
            }
        }

        if (modalType === 'create') {
            const params = {
                login: formData.login,
                password: formData.password,
                passwordRepeat: formData.passwordRepeat,
                city: formData.city, // ✅ Передаем city
                name: formData.name, // ✅ Передаем name
            }

            if (formData.password === formData.passwordRepeat) {
                try {
                    console.log(params)
                    const response = await authProvider.registration(params)
                    if (response.status === 201) {
                        setFormData({
                            login: '',
                            password: '',
                            passwordRepeat: '',
                            name: '',
                            city: '', 
                        })
                        window.location.href =
                            process.env.REACT_APP_PUBLIC_URL + '/admin'
                        //setMessage('Регистрация успешна! Вы можете войти.')
                    } else {
                        setMessage('Ошибка регистрации! Попробуйте снова.')
                    }
                } catch (error) {
                    setMessage('Ошибка регистрации!')
                }
            }
        }
    }

    return (
        <div className="modal-overlay">
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="modalBl bg-white rounded-lg overflow-hidden shadow-xl p-8 w-full max-w-md">
                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-gray-800 text-left">
                            {modalType === 'auth'
                                ? 'Авторизация'
                                : 'Регистрация'}
                        </h2>
                    </div>
                    <div className="flex justify-between mb-10">
                        <button
                            className={`flex-1 text-center py-2 border border-gray-300 rounded-md mr-2 ${modalType === 'auth' ? 'btnTopActive' : 'btnTop'}`}
                            onClick={() => setType('auth')}
                        >
                            Войти
                        </button>
                        <button
                            className={`flex-1 text-center py-2 border border-gray-300 rounded-md ml-2 ${modalType === 'create' ? 'btnTopActive' : 'btnTop'}`}
                            onClick={() => setType('create')}
                        >
                            Создать аккаунт
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="Телефон"
                                className="w-full py-2 px-3 border border-gray-300 rounded-md text-black"
                                name="login"
                                value={formData.login}
                                onChange={handleChange}
                            />
                        </div>
                        {modalType === 'create' && (
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Имя или название"
                                    className="w-full py-2 px-3 border border-gray-300 rounded-md text-black"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                        <div className="mb-4">
                            <input
                                type="password"
                                name="password"
                                placeholder="Пароль"
                                className="w-full py-2 px-3 border border-gray-300 rounded-md text-black"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        {modalType === 'create' && (
                            <div className="mb-4">
                                <input
                                    type="password"
                                    placeholder="Повторите пароль"
                                    className="w-full py-2 px-3 border border-gray-300 rounded-md text-black"
                                    name="passwordRepeat"
                                    value={formData.passwordRepeat}
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                        {modalType === 'create' && (
                            <CitySearchComponent
                                onCitySelect={handleCitySelect}
                            />
                        )}
                        <div className="sendMessage">{message}</div>
                        <button
                            type="submit"
                            className="w-full text-white py-2 rounded-md btnGrey"
                            ref={
                                modalType === 'auth' ? buttonAuth : buttonCreate
                            }
                        >
                            {modalType === 'auth' ? 'Войти' : 'Создать'}
                        </button>
                    </form>
                    <button onClick={onClose} className="w-full text-gray-600">
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalAuth
