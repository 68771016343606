// Header.jsx
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import './Header.css' // Стили для кнопок бургера
import ModalAuth from './ModalAuth/ModalAuth'
import authProvider from '../../../../authProvider'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import dataProvider from '../../../../dataProvider'

const Header = (props) => {
    const [isAuth, setIsAuth] = useState(false)
    const myDataProvider = dataProvider()
    const navigate = useNavigate()

    //Проверка авторизации и переброс в админ панель
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const isAuthenticated = await checkValidAuth() // Ждем выполнения
                console.log(isAuthenticated)
                setIsAuth(isAuthenticated) // Устанавливаем результат проверки
            } catch (error) {
                console.error('Ошибка проверки авторизации:', error)
                setIsAuth(false)
            }
        }

        checkAuth()
    }, [])

    const [isNavMenuVisible, setNavMenuVisible] = useState(false)
    const [modalType, setModalType] = useState('auth')
    const handleNavToggleClick = () => {
        setNavMenuVisible((prev) => !prev)
        const navContent = document.getElementById('nav-content')
        if (navContent) {
            if (!isNavMenuVisible) {
                navContent.classList.remove('hidden')
            } else {
                navContent.classList.add('hidden')
            }
        }
    }

    const [isModalOpen, setModalOpen] = useState(false)
    const openModal = (type) => () => {
        setModalType(type)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }
    // Обработчик для клика внутри элемента
    const handleNavContentClick = () => {
        // Если необходимо выполнить какие-то дополнительные действия при клике внутри элемента, добавьте их здесь
    }

    async function checkValidAuth() {
        try {
            const response = await myDataProvider.get(`auth/checkRole`, {
                withCredentials: true,
            })
            return true // Если запрос успешен, значит пользователь авторизован
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.clear()
                return false
            }
            return false
        }
    }

    return (
        <nav
            ref={props.headerRef}
            id="header"
            className="fixed w-full z-30 top-0 text-white"
        >
            <ModalAuth
                isOpen={isModalOpen}
                onClose={closeModal}
                type={modalType}
            ></ModalAuth>
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                <div className="pl-4 flex items-center">
                    <Link
                        to="#"
                        className="logo toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                    >
                        Cclients.ru
                    </Link>
                </div>
                <div className="block lg:hidden pr-4">
                    <button
                        id="nav-toggle"
                        className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                        onClick={handleNavToggleClick}
                    >
                        <svg
                            className="fill-current h-6 w-6"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                {/* {isNavMenuVisible && ( */}
                <div
                    ref={props.navRef}
                    className="main-header-mob w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                    id="nav-content"
                >
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className="mr-3">
                            <Link
                                to="#"
                                className="inline-block py-2 px-4 navMenuText font-bold no-underline"
                                href="#"
                            >
                                Главная
                            </Link>
                        </li>
                        <li className="mr-3">
                            <a
                                className="inline-block navMenuText no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                                href="#targetOpportunities"
                            >
                                Возможности
                            </a>
                        </li>
                        <li className="mr-3">
                            <a
                                className="inline-block navMenuText no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                                href="#targetPrice"
                            >
                                Цены
                            </a>
                        </li>
                    </ul>
                    {console.log(process.env.REACT_APP_LINK_ADMIN)}
                    {isAuth ? (
                        <button
                            ref={props.navactionRef}
                            id="navAction"
                            className="mx-auto lg:mx-3 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                            onClick={() =>
                                (window.location.href =
                                    process.env.REACT_APP_LINK_ADMIN)
                            }
                        >
                            Панель управления
                        </button>
                    ) : (
                        <>
                            <button
                                ref={props.navactionRef}
                                id="navAction"
                                className="mx-auto lg:mx-3 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                onClick={openModal('auth')}
                            >
                                Войти
                            </button>
                            <button
                                ref={props.navactionRef}
                                id="navAction"
                                className="btnOrange mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                                onClick={openModal('create')}
                            >
                                Создать
                            </button>
                        </>
                    )}
                </div>
            </div>
            <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
        </nav>
    )
}

export default Header
