import jwtInterceptor from './axiosRequest'

const dataProvider = () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/api'
    return {
        getList: (resource, params) => {
            params.projectId = localStorage.getItem('projectId')
            return jwtInterceptor
                .get(`${apiUrl}/${resource}`, {
                    params: params,
                })
                .then((response) => {
                    // console.log(response.data);
                    return response.data
                })
                .catch((error) => {
                    console.log(error)
                    return Promise.reject(error)
                })
        },
        customGetList: (resource, params) => {
            return jwtInterceptor
                .get(`${apiUrl}/${resource}`, {
                    params: params,
                })
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    console.log(error)
                    return Promise.reject(error)
                })
        },
        getOne: (resource, params) => {
            params.projectId = localStorage.getItem('projectId')
            return jwtInterceptor
                .get(`${apiUrl}/${resource}/${params.id}`, {
                    params: params,
                })
                .then((response) => {
                    // console.log(response);
                    return response
                })
                .catch((error) => {
                    console.log(error)
                    return Promise.reject(error)
                })
        },
        getMany: (resource, params) => {
            return jwtInterceptor
                .get(
                    `${apiUrl}/${resource}?ids=${JSON.stringify({ id: params.ids })}`,
                )
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getManyReference: () => Promise.reject(),
        update: (resource, params) => {
            console.log(params)
            console.log(`${apiUrl}/${resource}/${params.id}`)
            return jwtInterceptor
                .patch(`${apiUrl}/${resource}/${params.id}`, params)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateMany: () => {
            console.log('updateMany')
            Promise.reject()
        },
        create: (resource, params) => {
            params.projectId = localStorage.getItem('projectId')
            return jwtInterceptor
                .post(`${apiUrl}/${resource}`, params)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        delete: (resource, params) => {
            return jwtInterceptor
                .delete(`${apiUrl}/${resource}/${params.id}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        deleteMany: (resource, params) => {
            return jwtInterceptor
                .delete(
                    `${apiUrl}/${resource}?filter=${JSON.stringify({ id: params.ids })}`,
                )
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getListBenefit: (resource, params) => {
            return jwtInterceptor
                .get(`${apiUrl}/${resource}/${params.id}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        get: async (resource) => {
            try {
                const url = `${apiUrl}/${resource}`
                const response = jwtInterceptor.get(url)
                return response
            } catch (error) {
                return error.response || { data: null, status: 'error' }
            }
        },
        post: (resource, data) => {
            return jwtInterceptor
                .post(`${apiUrl}/${resource}`, data)
                .then((response) => {
                    console.log(response)
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    }
}

export default dataProvider
