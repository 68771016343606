import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    setProject,
    loadProjectFromStorage,
} from '../../redux/slices/ProjectSlice'
import Header from '../../components/ClientPages/UI/Header/Header'
import MainBlock from '../../components/ClientPages/UI/MainBlock/MainBlock'
import { Helmet } from 'react-helmet'
import { apiUrl } from '../../utils/constants'

function ClientPage() {
    const { id } = useParams()
    const projectId = Number(id) // Преобразуем в число, чтобы избежать несоответствий типов
    const dispatch = useDispatch()
    const projectData = useSelector((state) => state.project.project)

    const [loading, setLoading] = useState(true)
    const [findUser, setFindUser] = useState(true)

    // Загружаем данные из localStorage один раз при первом рендере
    useEffect(() => {
        dispatch(loadProjectFromStorage())
    }, [dispatch])

    // Проверяем, совпадает ли ID проекта в store с ID из роута, если нет — загружаем
    useEffect(() => {
        if (!projectData || projectData.id !== projectId) {
            setLoading(true)
            axios
                .get(`${apiUrl}/api/project/full/${projectId}`)
                .then((response) => {
                    if (response.data) {
                        dispatch(setProject(response.data)) // Обновляем store с новым проектом
                        setFindUser(true)
                    } else {
                        setFindUser(false)
                    }
                })
                .catch(() => setFindUser(false))
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [projectId, dispatch])

    if (loading) return <div>Loading...</div>
    if (!findUser) return <div>Пользователь не найден</div>

    return (
        <div>
            <Helmet>
                <title>{projectData?.name}</title>
                <meta
                    name="description"
                    content="Создайте сайт с онлайн записью для вашего бизнеса за 20 секунд. Управляйте услугами и клиентами легко и эффективно."
                />
            </Helmet>
            <Header data={projectData} />
            <MainBlock />
        </div>
    )
}

export default ClientPage
