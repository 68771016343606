import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import projectReducer from './slices/ProjectSlice'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['project'], // Сохраняем только project
}

const rootReducer = combineReducers({
    project: projectReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
})

const persistor = persistStore(store)

export { store, persistor }
