import axios from 'axios'
import JwtManager from './JwtManager'

// Создаем экземпляр axios с интерсепторами
const jwtInterceptor = axios.create()

// Запрос с добавлением авторизационного токена
jwtInterceptor.interceptors.request.use((config) => {
    const token = JwtManager.getToken()

    if (token) {
        // Добавляем Authorization заголовок с Bearer токеном
        config.headers['Authorization'] = `Bearer ${token}`
    }

    // Убедитесь, что с запросом отправляются куки
    config.withCredentials = true

    return config
})

// Ответы и обработка ошибок
jwtInterceptor.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const prevRequest = error.config

        // Если получаем ошибку 401 и запрос еще не был повторен
        if (error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true

            try {
                // Пытаемся обновить токен с помощью refresh token
                const refreshToken = JwtManager.getRefreshToken()
                if (!refreshToken) {
                    throw new Error('Refresh token is missing')
                }

                // Отправляем запрос на обновление токенов
                const response = await axios.get(
                    JwtManager.getRefreshTokenEndpoint(),
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                        withCredentials: true, // Для отправки куков
                    },
                )

                // Обновляем токены
                JwtManager.setToken(response.data.access_token)
                JwtManager.setRefreshToken(response.data.refresh_token)

                // Повторяем исходный запрос с новым токеном
                prevRequest.headers['Authorization'] =
                    `Bearer ${JwtManager.getToken()}`
                return axios(prevRequest) // Возвращаем новый запрос с актуальным токеном
            } catch (e) {
                // Если ошибка при обновлении токенов, очищаем токены
                JwtManager.eraseToken()
                return Promise.reject(e)
            }
        }

        return Promise.reject(error) // Возвращаем ошибку, если это не ошибка авторизации
    },
)

export default jwtInterceptor
