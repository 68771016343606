import JWTManager from './JwtManager'
import axios from 'axios'

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
        console.log(`${API_ENDPOINT}/api/auth/login`)
        return axios
            .post(`${API_ENDPOINT}/api/auth/login`, {
                login: username,
                password: password,
            })
            .then((response) => {
                JWTManager.removeProjectId()
                const permissions = []
                for (const key in response.data.user.roles) {
                    permissions.push(response.data.user.roles[key].name)
                }
                JWTManager.setRoles(JSON.stringify(permissions))
                JWTManager.setToken(response.data.access_token)
                JWTManager.setRefreshToken(response.data.refresh_token)
                return response
            })
            .catch((error) => {
                console.log(error)
            })
    },
    //Регистрация аккаунта
    registration: (params) => {
        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
        return axios
            .post(`${API_ENDPOINT}/api/auth/registration`, params, {
                withCredentials: true,
            })
            .then((response) => {
                JWTManager.removeProjectId()
                const permissions = []
                for (const key in response.data.user.roles) {
                    permissions.push(response.data.user.roles[key].name)
                }
                JWTManager.setRoles(JSON.stringify(permissions))
                JWTManager.setToken(response.data.access_token)
                JWTManager.setRefreshToken(response.data.refresh_token)
                return response
            })
            .catch((error) => {
                console.log(error)
            })
    },

    logout: () => {
        JWTManager.eraseToken()
        return Promise.resolve()
    },

    checkAuth: () => {
        return JWTManager.getToken() ? Promise.resolve() : Promise.reject()
    },

    checkError: (error) => {
        const status = error.status
        if (
            status === 401 ||
            status === 403 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403
        ) {
            JWTManager.eraseToken()
            return Promise.reject()
        }
        return Promise.resolve()
    },

    getPermissions: () => {
        const roles = JSON.parse(JWTManager.getRoles())
        return roles ? Promise.resolve(roles) : Promise.reject()
    },
}

export default authProvider
