import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import classes from './mainBlock.module.css'
import BrandBtn from '../Buttons/BrandBtn'
import Contact from './Contact'
import DOMPurify from 'dompurify'

const MainBlock = () => {
    const project = useSelector((state) => state.project.project) // Берем текущий проект

    if (!project) {
        return <div>Загрузка...</div>
    }

    const { id, mainInfo, projectType } = project
    const description = mainInfo?.description || 'Описание отсутствует'
    const cleanHTML = DOMPurify.sanitize(description)
    return (
        <div className={classes.main_block}>
            <Helmet>
                <meta name="description" content={description} />
            </Helmet>
            <div className={classes.online}>
                {projectType === 'specialist' ? (
                    <Link to="master" state={{ recordData: id }}>
                        <BrandBtn body="Услуги" />
                    </Link>
                ) : (
                    <Link to="master" state={{ recordData: id }}>
                        <BrandBtn body="Онлайн запись" />
                    </Link>
                )}
            </div>
            <div className={classes.about}>
                <div className={classes.about_title}>
                    {mainInfo?.title || 'О Салоне'}
                </div>
                <div
                    className={classes.about_body}
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                />
                <Contact settings={project} />
            </div>
        </div>
    )
}

export default MainBlock
